<template>
  <div
    id="diagram-donut"
    class="
      donut-diagram
      part-component part-diagram
      flex
      layout-align-start-start layout-column
    "
  >
    <div
      ref="diagram"
      class="diagram-wrap parallax-item parallax-relative"
      data-depth="1.2"
    >
      <svg-donut></svg-donut>

      <div class="donut-text ring">
        <div class="ring-text-wrap ring-outer absolute-center">
          <div
            class="ring-inner"
            v-for="(item, i) in controls.outer"
            :key="item.icon"
            :data-no="i"
          >
            <div class="ring-text">
              {{ item }}
            </div>
          </div>
        </div>

        <div class="ring-text-wrap ring-middle absolute-center din">
          <div
            class="ring-inner"
            v-for="(item, i) in controls.middle"
            :key="i"
            :data-item="item.icon"
            :data-no="i"
          >
            <div class="ring-text">
              <i :class="`ai-${item.icon}`"></i>
              {{ item.name }}
            </div>
          </div>
        </div>

        <div class="ring-logo absolute-center">
          <img
            class="logo-img-logo parallax-item parallax-relative"
            :src="`${$urls.img}/londolozi-logo-wink.${winkExt}`"
            ref="logoImg"
            data-depth=".8"
          />
        </div>
      </div>
    </div>
    <h3
      ref="title"
      class="
        diagram-heading
        normal
        parallax-item parallax-relative
        section-heading
      "
      data-depth="1.8"
    >
      {{ title }}
    </h3>
  </div>
</template>

<script>
import { partComponent, winkLogoMixin } from "@/mixins/core.js";
import SvgDonut from "@/components/misc/SvgDonut.vue";

export default {
  name: "DiagramDonut",
  components: {
    SvgDonut,
  },
  mixins: [partComponent, winkLogoMixin],
  props: ["show", "controls", "wink"],
  data() {
    return {
      title: "Londolozi Adapted Donut Economy",
      winked: false,
    };
  },
  watch: {
    wink(val) {
      if (val && !this.winked) {
        this.winkLogo();
        this.winked = true;
      }
    },
  },
  methods: {
    createTimeline() {
      const { title, diagram } = this.$refs;

      const tl = new TimelineMax({
        paused: true,
      });

      tl.set(
        ".diagram-wrap",
        {
          autoAlpha: 1,
        },
        0
      )
        .from(
          diagram,
          1.6,
          {
            // ease:  Back.easeOut.config(1),
            ease: Power2.easeOut,
            scale: 0.9,
            rotation: -45,
            autoAlpha: 0,
          },
          0
        )
        .from(
          title,
          0.6,
          {
            autoAlpha: 0,
            y: "+=30",
            ease: Power2.easeOut,
          },
          "-=1"
        );

      this.timeline = tl;
    },
  },
};
</script>

<style scoped>
#diagram-donut {
  max-width: 658px;
  width: 100%;
}
.diagram-wrap {
  opacity: 1;
  max-width: 658px;
  width: 100%;
  padding-top: 100%;
  font-size: 1rem;
  background-size: 100%;
  position: relative;
  height: 0;
}
.diagram-wrap > * {
  top: 0;
}
.ring-logo img {
  padding-bottom: 0.5rem;
  width: 4.875em;
}
.donut-diagram h3 {
  margin: 2.2rem 0 0 4.3rem;
}
img {
  display: block;
}
#svgs-donut {
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 1;
  position: absolute;
}
.donut-text {
  z-index: 2;
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.ring-middle {
  height: 63%;
  width: 20%;
}
.ring-outer {
  width: 20%;
  height: 98%;
}
.ring-text {
  position: absolute;
  bottom: 1.2%;
  width: 7.4em;
  line-height: 1.1;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20%;
  height: 5em;
  text-align: center;
  transform: translate(-50%, 0);
  left: 50%;
  color: white;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.4);
  font-size: 0.95em;
}
.ring-text i {
  color: white;
  font-size: 2.3em;
  padding: 0.1em 0.2em 0 0;
}
.ring-middle .ring-text {
  text-align: left;
  font-size: 0.97em;
}
.ring-inner {
  height: 100%;
  width: 100%;
  position: absolute;
}
.ring-middle .ring-inner[data-no="0"] .ring-text,
.ring-middle .ring-inner[data-no="4"] .ring-text,
.ring-outer .ring-inner[data-no="0"] .ring-text,
.ring-outer .ring-inner[data-no="1"] .ring-text,
.ring-outer .ring-inner[data-no="2"] .ring-text,
.ring-outer .ring-inner[data-no="3"] .ring-text {
  top: 0.6%;
}
.ring-middle .ring-inner[data-no="0"] {
  transform: rotate(36deg);
}
.ring-middle .ring-inner[data-no="4"] {
  transform: rotate(-36deg);
}
.ring-middle .ring-inner[data-no="1"] {
  transform: rotate(-72deg);
}
.ring-middle .ring-inner[data-no="2"] {
  transform: rotate(0);
}
.ring-middle .ring-inner[data-no="3"] {
  transform: rotate(72deg);
}

.ring-outer .ring-inner[data-no="7"] .ring-text {
  width: 10rem;
}

.ring-outer .ring-inner[data-no="0"] {
  transform: rotate(-67.5deg);
}
.ring-outer .ring-inner[data-no="1"] {
  transform: rotate(-22.5deg);
}
.ring-outer .ring-inner[data-no="2"] {
  transform: rotate(22.5deg);
}
.ring-outer .ring-inner[data-no="3"] {
  transform: rotate(67.5deg);
}
.ring-outer .ring-inner[data-no="4"] {
  transform: rotate(-67.5deg);
}
.ring-outer .ring-inner[data-no="5"] {
  transform: rotate(-22.5deg);
}
.ring-outer .ring-inner[data-no="6"] {
  transform: rotate(22.5deg);
}
.ring-outer .ring-inner[data-no="7"] {
  transform: rotate(67.5deg);
}

.ring-middle .ring-inner[data-no="0"] .ring-text {
  width: 10em;
}
.ring-middle .ring-inner[data-no="1"] .ring-text {
  width: 7em;
}
.ring-middle .ring-inner[data-no="2"] .ring-text {
  width: 10em;
}
.ring-middle .ring-inner[data-no="3"] .ring-text {
  width: 8.2em;
}
.ring-middle .ring-inner[data-no="4"] .ring-text {
  width: 7.2em;
}

.ring-middle .ring-inner[data-no="0"] .ring-text i {
  padding-top: 0.4em;
}
.ring-middle .ring-inner[data-no="4"] .ring-text i {
  padding-top: 0.3em;
}
.ring-middle .ring-inner[data-no="2"] .ring-text i {
  padding-right: 0.1em;
}

.din.ring-middle {
  height: 66%;
  width: 20%;
}
.din.ring-middle .ring-text {
  font-weight: 200;
  font-family: "din";
  line-height: 0.95;
  font-size: 1.2em;
  text-transform: uppercase;
}
.din.ring-middle .ring-text i {
  padding: 0.1em 0.15em 0 0;
}

.din.ring-middle .ring-inner[data-no="0"] .ring-text {
  width: 8.6em;
}
.din.ring-middle .ring-inner[data-no="1"] .ring-text {
  width: 6.1em;
}
.din.ring-middle .ring-inner[data-no="2"] .ring-text {
  width: 8.7em;
}
.din.ring-middle .ring-inner[data-no="3"] .ring-text {
  width: 7.2em;
}
.din.ring-middle .ring-inner[data-no="4"] .ring-text {
  width: 5.8em;
}

.din.ring-middle .ring-inner[data-no="0"] .ring-text i {
  padding-top: 0.35em;
}
.din.ring-middle .ring-inner[data-no="0"] .ring-text i {
  padding-right: 0.04em;
}
.din.ring-middle .ring-inner[data-no="4"] .ring-text i {
  padding-top: 0.25em;
}
.din.ring-middle .ring-inner[data-no="2"] .ring-text i {
  padding-right: 0;
}
</style>
