<template>
  <div id="svgs-donut">
    <svg
      version="1.1"
      id="Layer_2"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 498.7 498.7"
      style="enable-background: new 0 0 498.7 498.7"
      xml:space="preserve"
    >
      <path
        id="XMLID_62_"
        class="st0"
        d="M249.3,498.7c137.7,0,249.3-111.6,249.3-249.3C498.7,111.6,387,0,249.3,0S0,111.6,0,249.3
	C0,387,111.6,498.7,249.3,498.7"
      />
    </svg>

    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 498.7 498.7"
      style="enable-background: new 0 0 498.7 498.7"
      xml:space="preserve"
      class="parallax-item"
      data-depth="0.6"
    >
      <g id="Layer_2">
        <path
          id="XMLID_17_"
          class="st1"
          d="M249.3,427.5c98.4,0,178.2-79.8,178.2-178.2c0-98.4-79.8-178.2-178.2-178.2
		S71.2,150.9,71.2,249.3C71.2,347.7,150.9,427.5,249.3,427.5"
        />
        <g id="XMLID_57_">
          <path
            id="XMLID_73_"
            class="st2"
            d="M377.1,342.9c-10.1,13.8-22,25.5-35.1,35l0,0l-92.6-128.5l150.8-48.4l0,0
			C415.1,247.5,408.3,300.3,377.1,342.9z"
          />
          <path
            id="XMLID_72_"
            class="st3"
            d="M342,377.8L342,377.8c-54.1,39.1-129.3,40.9-186.2-0.7l93.6-127.8L342,377.8z"
          />
          <path
            id="XMLID_71_"
            class="st4"
            d="M400.2,200.9L400.2,200.9l-150.8,48.4L249.9,91l0,0c32.3,0.1,64.9,10.1,93,30.6
			C371,142.1,390.3,170.1,400.2,200.9z"
          />
          <path
            id="XMLID_70_"
            class="st5"
            d="M249.3,249.3l-93.6,127.8c-56.8-41.6-77.9-113.9-57-177.3l0.1,0L249.3,249.3z"
          />
          <path
            id="XMLID_69_"
            class="st6"
            d="M249.9,91l-0.6,158.4L98.9,199.8l-0.1,0c5.1-15.4,12.6-30.3,22.7-44
			C152.7,113.2,201,90.8,249.9,91L249.9,91z"
          />
        </g>
        <g id="XMLID_3_">
          <path
            id="XMLID_20_"
            class="st7"
            d="M249.3,347c54,0,97.7-43.7,97.7-97.7s-43.7-97.7-97.7-97.7c-54,0-97.7,43.7-97.7,97.7
			S195.4,347,249.3,347"
          />
        </g>
        <g id="XMLID_2_">
          <path
            id="XMLID_19_"
            class="st8"
            d="M249.3,327.6c43.2,0,78.2-35,78.2-78.2s-35-78.2-78.2-78.2c-43.2,0-78.2,35-78.2,78.2
			S206.1,327.6,249.3,327.6"
          />
        </g>
      </g>
      <g id="Layer_1">
        <g id="XMLID_22_">
          <text
            id="XMLID_142_"
            transform="matrix(0.488 -0.8729 0.8729 0.488 174.4924 211.6645)"
            class="st8 st9 st10"
          >
            r
          </text>
          <text
            id="XMLID_141_"
            transform="matrix(0.5498 -0.8353 0.8353 0.5498 178.2356 205.146)"
            class="st8 st9 st10"
          >
            e
          </text>
          <text
            id="XMLID_140_"
            transform="matrix(0.6273 -0.7788 0.7788 0.6273 181.6769 199.8552)"
            class="st8 st9 st10"
          >
            d
          </text>
          <text
            id="XMLID_139_"
            transform="matrix(0.6986 -0.7155 0.7155 0.6986 187.3935 192.8525)"
            class="st8 st9 st10"
          >
            u
          </text>
          <text
            id="XMLID_138_"
            transform="matrix(0.7636 -0.6457 0.6457 0.7636 193.1316 187.1273)"
            class="st8 st9 st10"
          >
            c
          </text>
          <text
            id="XMLID_137_"
            transform="matrix(0.8218 -0.5697 0.5697 0.8218 199.1464 181.9876)"
            class="st8 st9 st10"
          >
            e
          </text>
          <text
            id="XMLID_133_"
            transform="matrix(0.9257 -0.3782 0.3782 0.9257 214.6221 172.7369)"
            class="st8 st9 st10"
          >
            |
          </text>
          <text
            id="XMLID_129_"
            transform="matrix(0.9865 -0.1635 0.1635 0.9865 231.3733 167.0836)"
            class="st8 st9 st10"
          >
            r
          </text>
          <text
            id="XMLID_128_"
            transform="matrix(0.9961 -8.846000e-002 8.846000e-002 0.9961 238.8571 165.8524)"
            class="st8 st9 st10"
          >
            e
          </text>
          <text
            id="XMLID_127_"
            transform="matrix(0.9999 -1.265000e-002 1.265000e-002 0.9999 245.0831 165.3356)"
            class="st8 st9 st10"
          >
            u
          </text>
          <text
            id="XMLID_126_"
            transform="matrix(0.9961 8.846000e-002 -8.846000e-002 0.9961 253.224 165.3374)"
            class="st8 st9 st10"
          >
            s
          </text>
          <text
            id="XMLID_125_"
            transform="matrix(0.9865 0.1635 -0.1635 0.9865 259.0055 165.7442)"
            class="st8 st9 st10"
          >
            e
          </text>
          <text
            id="XMLID_121_"
            transform="matrix(0.9347 0.3554 -0.3554 0.9347 276.6127 169.7909)"
            class="st8 st9 st10"
          >
            |
          </text>
          <text
            id="XMLID_117_"
            transform="matrix(0.8353 0.5498 -0.5498 0.8353 292.5268 177.2458)"
            class="st8 st9 st10"
          >
            r
          </text>
          <text
            id="XMLID_116_"
            transform="matrix(0.7788 0.6273 -0.6273 0.7788 298.8327 181.4407)"
            class="st8 st9 st10"
          >
            e
          </text>
          <text
            id="XMLID_115_"
            transform="matrix(0.7319 0.6814 -0.6814 0.7319 303.7483 185.3517)"
            class="st8 st9 st10"
          >
            c
          </text>
          <text
            id="XMLID_114_"
            transform="matrix(0.6637 0.748 -0.748 0.6637 309.6895 190.8901)"
            class="st8 st9 st10"
          >
            y
          </text>
          <text
            id="XMLID_113_"
            transform="matrix(0.5893 0.8079 -0.8079 0.5893 314.0233 195.6501)"
            class="st8 st9 st10"
          >
            c
          </text>
          <text
            id="XMLID_112_"
            transform="matrix(0.5295 0.8483 -0.8483 0.5295 318.642 202.1379)"
            class="st8 st9 st10"
          >
            l
          </text>
          <text
            id="XMLID_111_"
            transform="matrix(0.4667 0.8844 -0.8844 0.4667 321.9337 207.2773)"
            class="st8 st9 st10"
          >
            e
          </text>
        </g>
        <g id="XMLID_23_">
          <text
            id="XMLID_49_"
            transform="matrix(0.5987 0.801 -0.801 0.5987 177.415 303.1413)"
            class="st8 st9 st10"
          >
            c
          </text>
          <text
            id="XMLID_48_"
            transform="matrix(0.6728 0.7398 -0.7398 0.6728 182.1507 309.3667)"
            class="st8 st9 st10"
          >
            o
          </text>
          <text
            id="XMLID_47_"
            transform="matrix(0.756 0.6546 -0.6546 0.756 188.0352 315.8258)"
            class="st8 st9 st10"
          >
            n
          </text>
          <text
            id="XMLID_46_"
            transform="matrix(0.8007 0.599 -0.599 0.8007 195.0383 321.7008)"
            class="st8 st9 st10"
          >
            s
          </text>
          <text
            id="XMLID_45_"
            transform="matrix(0.8541 0.5202 -0.5202 0.8541 199.9094 325.33)"
            class="st8 st9 st10"
          >
            c
          </text>
          <text
            id="XMLID_44_"
            transform="matrix(0.8891 0.4577 -0.4577 0.8891 206.6145 329.4403)"
            class="st8 st9 st10"
          >
            i
          </text>
          <text
            id="XMLID_43_"
            transform="matrix(0.9197 0.3926 -0.3926 0.9197 210.6254 331.5643)"
            class="st8 st9 st10"
          >
            o
          </text>
          <text
            id="XMLID_42_"
            transform="matrix(0.9533 0.302 -0.302 0.9533 218.8184 335.0978)"
            class="st8 st9 st10"
          >
            u
          </text>
          <text
            id="XMLID_41_"
            transform="matrix(0.9728 0.2316 -0.2316 0.9728 226.6424 337.4137)"
            class="st8 st9 st10"
          >
            s
          </text>
          <text
            id="XMLID_40_"
            transform="matrix(0.9908 0.1353 -0.1353 0.9908 232.3005 338.8971)"
            class="st8 st9 st10"
          >
            n
          </text>
          <text
            id="XMLID_39_"
            transform="matrix(0.9981 6.171000e-002 -6.171000e-002 0.9981 241.2172 339.992)"
            class="st8 st9 st10"
          >
            e
          </text>
          <text
            id="XMLID_38_"
            transform="matrix(0.9999 -1.234000e-002 1.234000e-002 0.9999 247.4535 340.3994)"
            class="st8 st9 st10"
          >
            s
          </text>
          <text
            id="XMLID_37_"
            transform="matrix(0.9963 -8.631999e-002 8.631999e-002 0.9963 253.5275 340.3913)"
            class="st8 st9 st10"
          >
            s
          </text>
          <text
            id="XMLID_35_"
            transform="matrix(0.983 -0.1838 0.1838 0.983 263.4286 339.2711)"
            class="st8 st9 st10"
          >
            a
          </text>
          <text
            id="XMLID_34_"
            transform="matrix(0.9604 -0.2788 0.2788 0.9604 269.8767 338.0803)"
            class="st8 st9 st10"
          >
            w
          </text>
          <text
            id="XMLID_33_"
            transform="matrix(0.9289 -0.3703 0.3703 0.9289 279.5492 335.1351)"
            class="st8 st9 st11"
          >
            a
          </text>
          <text
            id="XMLID_32_"
            transform="matrix(0.8891 -0.4577 0.4577 0.8891 286.149 332.4803)"
            class="st8 st9 st10"
          >
            k
          </text>
          <text
            id="XMLID_31_"
            transform="matrix(0.8541 -0.5202 0.5202 0.8541 292.7847 329.124)"
            class="st8 st9 st10"
          >
            e
          </text>
          <text
            id="XMLID_30_"
            transform="matrix(0.8007 -0.599 0.599 0.8007 298.1776 325.8439)"
            class="st8 st9 st10"
          >
            n
          </text>
          <text
            id="XMLID_29_"
            transform="matrix(0.756 -0.6546 0.6546 0.756 305.474 320.4051)"
            class="st8 st9 st10"
          >
            i
          </text>
          <text
            id="XMLID_28_"
            transform="matrix(0.7074 -0.7069 0.7069 0.7074 308.8031 317.6001)"
            class="st8 st9 st10"
          >
            n
          </text>
          <text
            id="XMLID_27_"
            transform="matrix(0.6366 -0.7712 0.7712 0.6366 315.1162 311.1293)"
            class="st8 st9 st11"
          >
            g
          </text>
        </g>
        <g id="XMLID_1_">
          <text
            id="XMLID_61_"
            transform="matrix(0.928 -0.3725 0.3725 0.928 183.0739 99.1332)"
            class="st12 st9 st13"
          >
            i
          </text>
          <text
            id="XMLID_60_"
            transform="matrix(0.9452 -0.3264 0.3264 0.9452 188.2357 97.0495)"
            class="st12 st9 st13"
          >
            n
          </text>
          <text
            id="XMLID_59_"
            transform="matrix(0.9636 -0.2672 0.2672 0.9636 199.136 93.334)"
            class="st12 st9 st13"
          >
            f
          </text>
          <text
            id="XMLID_58_"
            transform="matrix(0.9758 -0.2188 0.2188 0.9758 206.5514 91.212)"
            class="st12 st9 st13"
          >
            o
          </text>
          <text
            id="XMLID_56_"
            transform="matrix(0.9876 -0.1573 0.1573 0.9876 217.4177 88.8899)"
            class="st12 st9 st13"
          >
            r
          </text>
          <text
            id="XMLID_55_"
            transform="matrix(0.9966 -8.216999e-002 8.216999e-002 0.9966 226.8791 87.3089)"
            class="st12 st9 st14"
          >
            m
          </text>
          <text
            id="XMLID_54_"
            transform="matrix(0.9995 -3.165000e-002 3.165000e-002 0.9995 239.9861 86.3351)"
            class="st12 st9 st14"
          >
            a
          </text>
          <text
            id="XMLID_53_"
            transform="matrix(0.9998 1.900000e-002 -1.900000e-002 0.9998 248.0893 86.108)"
            class="st12 st9 st13"
          >
            t
          </text>
          <text
            id="XMLID_52_"
            transform="matrix(0.9976 6.956000e-002 -6.956000e-002 0.9976 256.9424 86.2611)"
            class="st12 st9 st13"
          >
            i
          </text>
          <text
            id="XMLID_51_"
            transform="matrix(0.9942 0.1073 -0.1073 0.9942 262.6124 86.6141)"
            class="st12 st9 st13"
          >
            o
          </text>
          <text
            id="XMLID_50_"
            transform="matrix(0.9833 0.182 -0.182 0.9833 273.5624 87.8494)"
            class="st12 st9 st13"
          >
            n
          </text>
          <text
            id="XMLID_25_"
            transform="matrix(0.9636 0.2672 -0.2672 0.9636 289.9378 91.1476)"
            class="st12 st9 st13"
          >
            a
          </text>
          <text
            id="XMLID_21_"
            transform="matrix(0.9452 0.3264 -0.3264 0.9452 298.6423 93.4704)"
            class="st12 st9 st13"
          >
            g
          </text>
          <text
            id="XMLID_18_"
            transform="matrix(0.928 0.3725 -0.3725 0.928 308.7808 97.0071)"
            class="st12 st9 st13"
          >
            e
          </text>
        </g>
        <g id="XMLID_63_">
          <text
            id="XMLID_84_"
            transform="matrix(0.928 0.3725 -0.3725 0.928 182.4906 407.7571)"
            class="st12 st9 st13"
          >
            h
          </text>
          <text
            id="XMLID_83_"
            transform="matrix(0.9492 0.3147 -0.3147 0.9492 193.3352 412.0247)"
            class="st12 st9 st13"
          >
            e
          </text>
          <text
            id="XMLID_82_"
            transform="matrix(0.9636 0.2672 -0.2672 0.9636 200.9084 414.5914)"
            class="st12 st9 st13"
          >
            a
          </text>
          <text
            id="XMLID_81_"
            transform="matrix(0.9758 0.2188 -0.2188 0.9758 209.528 416.8909)"
            class="st12 st9 st13"
          >
            r
          </text>
          <text
            id="XMLID_80_"
            transform="matrix(0.9855 0.1696 -0.1696 0.9855 218.3331 418.7874)"
            class="st12 st9 st13"
          >
            t
          </text>
          <text
            id="XMLID_78_"
            transform="matrix(0.9966 8.216999e-002 -8.216999e-002 0.9966 231.9626 420.9576)"
            class="st12 st9 st14"
          >
            l
          </text>
          <text
            id="XMLID_77_"
            transform="matrix(0.999 4.431000e-002 -4.431000e-002 0.999 239.6972 421.6367)"
            class="st12 st9 st14"
          >
            e
          </text>
          <text
            id="XMLID_76_"
            transform="matrix(1 -6.330000e-003 6.330000e-003 1 247.7153 421.9247)"
            class="st12 st9 st14"
          >
            a
          </text>
          <text
            id="XMLID_75_"
            transform="matrix(0.9976 -6.956000e-002 6.956000e-002 0.9976 256.68 421.9723)"
            class="st12 st9 st13"
          >
            d
          </text>
          <text
            id="XMLID_74_"
            transform="matrix(0.9928 -0.1198 0.1198 0.9928 267.9065 421.1192)"
            class="st12 st9 st14"
          >
            e
          </text>
          <text
            id="XMLID_68_"
            transform="matrix(0.9855 -0.1696 0.1696 0.9855 275.8508 420.135)"
            class="st12 st9 st13"
          >
            r
          </text>
          <text
            id="XMLID_67_"
            transform="matrix(0.9758 -0.2188 0.2188 0.9758 285.2236 418.4586)"
            class="st12 st9 st13"
          >
            s
          </text>
          <text
            id="XMLID_66_"
            transform="matrix(0.9602 -0.2792 0.2792 0.9602 292.4102 417.003)"
            class="st12 st9 st13"
          >
            h
          </text>
          <text
            id="XMLID_65_"
            transform="matrix(0.9452 -0.3264 0.3264 0.9452 303.6899 413.6929)"
            class="st12 st9 st13"
          >
            i
          </text>
          <text
            id="XMLID_64_"
            transform="matrix(0.9325 -0.3611 0.3611 0.9325 308.932 411.9346)"
            class="st12 st9 st13"
          >
            p
          </text>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "SvgDonut",
};
</script>

<style scoped>
svg {
  position: absolute;
  filter: drop-shadow(0px 3px 0.7em rgba(0, 0, 0, 0.4));
}
#Layer_2 {
}
.st0 {
  fill: #476c38;
  fill: #666666;
  opacity: 0.65;
}
.st1 {
  fill: #dcddde;
}
.st2 {
  fill: #638d42;
  stroke: #dcddde;
  stroke-width: 2;
  stroke-miterlimit: 10;
}
.st3 {
  fill: #00a6ca;
  stroke: #dcddde;
  stroke-width: 2;
  stroke-miterlimit: 10;
}
.st4 {
  fill: #ed2176;
  stroke: #dcddde;
  stroke-width: 2;
  stroke-miterlimit: 10;
}
.st5 {
  fill: #5b7f9e;
  stroke: #dcddde;
  stroke-width: 2;
  stroke-miterlimit: 10;
}
.st6 {
  fill: #d2232a;
  stroke: #dcddde;
  stroke-width: 2;
  stroke-miterlimit: 10;
}
.st7 {
  fill: #3c6070;
}
.st8 {
  fill: #ffffff;
}
.st9 {
  font-family: "trajan";
}
.st10 {
  font-size: 10.4584px;
}
.st11 {
  font-size: 10.4585px;
}
.st12 {
  fill: #231f20;
}
.st13 {
  font-size: 13.3031px;
}
.st14 {
  font-size: 13.3032px;
}
</style>
